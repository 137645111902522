<template>
  <div v-editable="blok" class="sb-page isolate flex flex-grow flex-col gap-10" :style="`--bg-scale: ${circleZoom}; --zoom-duration: ${zoomDuration}ms`">
    <storyblok-component v-for="childBlok in blok.body" :key="childBlok._uid" :blok="childBlok" class="z-10" />
  </div>
</template>

<script setup lang="ts">
import type { PageStoryblok } from "~/component-types-sb"

const props = defineProps<{ blok: PageStoryblok }>()
const circleZoom = ref(0)
const zoomDuration = ref(0)

usePageSeo(props.blok)

function scaleBg() {
  const zoom = ((window.scrollY) / (window.document.body.scrollHeight - window.innerHeight))

  circleZoom.value = isNaN(zoom) ? 1 : (1 - Math.cos((zoom * Math.PI) / 2.5)) + 1
}

onMounted(() => {
  window.addEventListener("scroll", scaleBg, { passive: true })
  scaleBg()

  if (!document.referrer || new URL(document.referrer).origin !== window.location.origin) {
    zoomDuration.value = 400
  }

  // slow appear animation, faster scroll animation after that
  setTimeout(() => (zoomDuration.value = 75), zoomDuration.value)

  setTimeout(() => {
    window.scrollTo(0, location.hash ? (document.querySelector(useRoute().hash) as HTMLElement).getBoundingClientRect().top + window.scrollY - 120 : 0)
  }, 100)
})

onUnmounted(() => window.removeEventListener("scroll", scaleBg))
</script>

<style>
.sb-page::before {
  --bg-size: max(75dvh,75dvw);

  @apply pointer-events-none fixed left-1/3 top-1/3 size-[--bg-size] origin-center -translate-x-1/2 -translate-y-1/2 scale-[--bg-scale] overflow-hidden rounded-full bg-white transition-[transform,width] duration-[--zoom-duration] ease-linear content-[''] dark:bg-white/10;
}

@screen tablet {
  .sb-page::before {
    /* --bg-size: 97vw; */
  }
}

@screen desktop {
  .sb-page::before {
    /* --bg-size: 85vw; */
  }
}
</style>
