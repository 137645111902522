import { storyKey } from "~/injection-symbols"

interface seoData {
  title?: string
  description?: string
  ogImage?: string
  ogImageAlt?: string
  twitterImage?: string
  twitterImageAlt?: string
}

export const usePageSeo = (blok: Record<string, any> & seoData) => {
  const story = inject(storyKey)
  const route = useRoute()

  const seoData: {
    title?: string
    description?: string
    ogImage?: string
    ogImageAlt?: string
    twitterImage?: string
    twitterImageAlt?: string
  } = {}

  if (route.path) {
    useHead({
      link: [
        {
          rel: "canonical",
          href: `${useSiteConfig().url}${route.path}`,
        },
      ],
    })
  }

  blok.noIndex &&
    useHead({
      meta: [{ name: "robots", content: "noindex" }],
    })

  seoData.title = blok.title || story?.name
  blok.description && (seoData.description = blok.description)
  blok.image?.filename && (seoData.ogImage = blok.image?.filename)
  blok.image?.alt && (seoData.ogImageAlt = blok.image?.alt)

  seoData.ogImage && (seoData.twitterImage = seoData.ogImage)
  seoData.ogImageAlt && (seoData.twitterImageAlt = seoData.ogImageAlt)

  if (
    seoData.title?.toLocaleLowerCase() === "startseite" ||
    seoData.title?.toLocaleLowerCase() === "index" ||
    seoData.title?.toLocaleLowerCase() === "home"
  ) {
    seoData.title = ""
  }

  useSeoMeta(seoData)
}
